import React, { ReactElement, useEffect } from 'react';

import { graphql } from 'gatsby';

import { RichText, SEO, Header, HEADER_TYPES } from '../components';
import { IAppContext } from '../context/app/app.types';
import { useAppContext } from '../hooks/context/appContext.hook';
import { IDefaultEntry } from '../types/default.types';

export interface IDefaultProps {
	data: any;
	pageContext: any;
}

export const Default: React.FC<IDefaultProps> = ({
	data,
}: IDefaultProps): ReactElement<any, any> | null => {
	const { setIsGridVisible }: IAppContext = useAppContext();

	const page: IDefaultEntry | undefined = data?.craft?.entry;

	useEffect(() => {
		setIsGridVisible(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!page) {
		return null;
	}

	const { description, seomatic } = page;

	return (
		<div className="u-mb-10">
			<SEO {...seomatic} />
			<div data-scroll-section>
				<Header type={HEADER_TYPES.Dark} />
			</div>

			<main className="u-stack u-layout">
				<section data-scroll-section>
					<RichText content={description} />
				</section>
			</main>
		</div>
	);
};

export default Default;

export const DefaultBySlug = graphql`
	query getDefaultBySlug($slug: [String]) {
		craft {
			entry(slug: $slug) {
				... on Craft_default_default_Entry {
					id
					title
					description
					seomatic {
						metaTitleContainer
						metaTagContainer
						metaLinkContainer
						metaJsonLdContainer
					}
				}
			}
		}
	}
`;
